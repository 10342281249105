var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome"
  }, [_c('sygni-rounded-button', {
    staticClass: "ml-4 mt-4 ml-auto mr-auto w-auto filled gn-primary",
    on: {
      "click": _vm.generatePdf
    }
  }, [_vm._v("Generate pdf")]), _c('div', {
    staticClass: "welcome__container"
  }, [_c('div', {
    staticClass: "welcome__main"
  }, [_c('div', {
    staticClass: "pdf-template"
  }, [_c('div', {
    ref: "pdfTemplate",
    staticClass: "ProseMirror",
    attrs: {
      "contenteditable": "false",
      "translate": "no",
      "tabindex": "0"
    }
  }, [_c('div', [_c('htmlpagefooter', {
    attrs: {
      "name": "myFooter1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "center",
      "margin-top": "5px"
    }
  }, [_vm._v("{PAGENO} / {nbpg}")])]), _c('sethtmlpagefooter', {
    attrs: {
      "name": "myFooter1",
      "value": "1",
      "page": "ALL"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "pageBreak"
  }), _c('p', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" ")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(1), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(2), _vm._m(3), _vm._m(4), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Definicje i interpretacja")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("W niniejszej Umowie poniższe pojęcia pisane wielką literą będą miały następujące znaczenie:")]), _vm._m(5), _vm._m(6), _vm._m(7), _vm._m(8), _vm._m(9), _vm._m(10), _vm._m(11), _vm._m(12), _vm._m(13), _vm._m(14), _vm._m(15), _vm._m(16), _vm._m(17), _vm._m(18), _vm._m(19), _vm._m(20), _vm._m(21), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("W niniejszej Umowie:")]), _vm._m(22), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Ustanowienie Administratora Hipoteki")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(23), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("2. Strony niniejszej Umowy zgodnie uznają, iż Przedsięwzięcie stanowi jedno przedsięwzięcie w rozumieniu art. 68² Ustawy o Księgach Wieczystych i Hipotece, sfinansowaniu, którego służą wierzytelności Pożyczkodawców wynikające z Umów Pożyczek.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("3. Michał Jasiński niniejszym przyjmuje powołanie na Administratora Hipoteki i zobowiązuje się do wykonywania przysługujących mu praw oraz ciążących na nim zobowiązań zgodnie i na zasadach określonych w niniejszej Umowie oraz w znajdujących zastosowanie przepisach prawa, w tym przepisach Ustawy o Księgach Wieczystych i Hipotece.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("a")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("a")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("a")]), _vm._m(24), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Ustanowienie Hipoteki")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("1. Administrator Hipoteki (działając we własnym imieniu, lecz na rachunek Pożyczkodawców) zawrze z Pożyczkobiorcą w celu zabezpieczenia wierzytelności pieniężnych Pożyczkodawców z tytułu Umów Pożyczek (w tym wierzytelności o spłatę kwot głównych pożyczek, zapłatę odsetek kapitałowych oraz odsetek za opóźnienie) umowę o ustanowienie, na prawie użytkowania wieczystego Nieruchomości, Hipoteki tj. hipoteki umownej łącznej do sumy 9 150 000,00 PLN (słownie: dziewięć milionów sto pięćdziesiąt tysięcy złotych), w terminie i na warunkach określonych w niniejszej Umowie oraz Umowach Pożyczek.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("2. Administrator Hipoteki zapewni, że żądanie wpisu Hipoteki, zawarte we wniosku o wpis Hipoteki do ksiąg wieczystych prowadzonych dla Nieruchomości, będzie określało wszystkich Pożyczkodawców (wskazując ich imiona i nazwiska) oraz wszystkie Umowy Pożyczek (wskazując numery i daty umów), w konsekwencji czego dane te zostaną ujawnione w księgach wieczystych.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("3. Administrator Hipoteki:")]), _vm._m(25), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(26), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(27), _vm._m(28), _vm._m(29), _vm._m(30), _vm._m(31), _vm._m(32), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("tekst")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("tekst")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("tekst")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("tekst")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("tekst")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ")]), _vm._m(33), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Podział kwot")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(34), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Administrator Hipoteki, w przypadku uzyskania zaspokojenia z Nieruchomości, dokona odpowiedniego rozliczenia pomiędzy Pożyczkodawcami kwot uzyskanych z tego tytułu. Podział każdej kwoty pochodzącej z zaspokojenia z Nieruchomości zostanie dokonany proporcjonalnie, do wielkości wierzytelności pieniężnych, jaką posiada każdy z Pożyczkodawców w stosunku do Pożyczkobiorcy z tytułu Umów Pożyczek. Administrator Hipoteki dokona wspomnianego wcześniej rozliczenia na rachunki bankowe wskazane przez Pożyczkodawców na piśmie.")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Wynagrodzenie Administratora Hipoteki")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("1. Pożyczkobiorca zobowiązuje się do zapłaty na rzecz Administratora Hipoteki, z tytułu całkowitego wynagrodzenia za czynności Administratora Hipoteki, jednorazowego wynagrodzenia ryczałtowego w wysokości 2.000,00 zł (słownie: dwa tysiące złotych).")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("2. Pożyczkodawca zobowiązuje się do zapłaty na rzecz Administratora Hipoteki wynagrodzenia ryczałtowego w wysokości 500,00 zł (słownie: pięćset złotych) za każdy rozpoczęty miesiąc kalendarzowy począwszy od miesiąca, w którym Administrator Hipoteki wykona, na podstawie niniejszej Umowy, pierwszą czynność w celu dochodzenia na rzecz Pożyczkodawcy zaspokojenia z przedmiotu Hipoteki.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("3. Kwoty wynagrodzeń wskazane w ust. 1 i ust. 2 powyżej nie obejmują należnego podatku od towarów i usług (VAT), który będzie doliczany w wysokości obowiązującej w dniu wystawiania faktur.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("4. Wynagrodzenie Administratora Hipoteki, o którym mowa powyżej w ust. 1, płatne będzie w terminie 7 dni od dnia zawarcia niniejszej Umowy na podstawie prawidłowo wystawionej faktury. Wynagrodzenie Administratora Hipoteki, o którym mowa powyżej w ust. 2, płatne będzie miesięcznie z góry, w terminie 7 dni od daty otrzymania prawidłowo wystawionej faktury.")]), _vm._m(35), _c('p', [_vm._v(" ")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("NIEWYŚWIETLANE ENTERY")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Pożyczkobiorca oraz Pożyczkodawca (jako dłużnicy solidarni) zobowiązują się do zwrotu Administratorowi Hipoteki wszelkich kosztów koniecznych w celu należytego")]), _vm._m(36), _vm._m(37), _vm._m(38), _vm._m(39), _vm._m(40), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(41), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(42), _vm._m(43), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("TABELA - wiersz na przełomie stron")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(44), _vm._m(45), _vm._m(46), _vm._m(47), _vm._m(48), _vm._m(49), _vm._m(50), _vm._m(51), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("TABELA - BRAK przesuwania krawędzi w całej tabeli, jeśli jest scalony wiersz ")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(52), _vm._m(53), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("TABELA - BRAK możliwosci dodawania wypełnienia wierszy")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("TABELA - BRAK możliwosci usuwania pojedynczych wierszy")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(54), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("TABELA - BRAK możliwości kopiowania zawartosci komórki z tabeli jeśli występuje enter")]), _c('hr', {
    attrs: {
      "contenteditable": "false"
    }
  }), _vm._m(55), _vm._m(56), _c('p', [_vm._v(" Za Pożyczkodawcę ")]), _c('p', [_vm._v(" ")]), _c('p', [_vm._v(" __________________________")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Jan")]), _vm._m(57), _vm._m(58), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ")]), _c('p', [_vm._v(" ")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ________________________________                                            ________________________________")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ")]), _c('p', [_vm._v("NIE KOPIUJE SIĘ NAGŁÓWEK/STOPKA")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ")]), _vm._m(59), _vm._m(60), _vm._m(61), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("TABULATOR DODANY W KREATORZE")])], 1)])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("UMOWA O POWOŁANIU ADMINISTRATORA HIPOTEKI")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('u', [_vm._v("PUNKTORY PRZY LICZBACH:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('u', [_vm._v("PUNKTORY PRZY LICZBACH:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ol', [_c('li', [_c('div', {
    staticClass: "counter"
  }, [_vm._v("1.")]), _c('p', {
    staticClass: "has-text-danger",
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("aaaaaaaaaaaaaaaa")])]), _c('li', [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.")]), _c('p', {
    staticClass: "has-text-danger",
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Jan Ryba zamieszkały/a w , adres: ul. nr lok. , , posiadający/a PESEL , legitymujący/a się ważnym do dnia roku (dalej „"), _c('strong', [_vm._v("Pożyczkodawca")]), _vm._v("”)")])]), _c('div', {
    staticClass: "ol"
  }, [_c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.1.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.2.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.3.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])]), _c('div', {
    staticClass: "ol"
  }, [_c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.3.1.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.3.2.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.3.3.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.3.4.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("2.4.")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur vitae modi similique, repellendus cupiditate quod!")])])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("3.")]), _c('p', {
    staticClass: "has-text-danger",
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("HIGH CAPITAL MANAGEMENT spółką z ograniczoną odpowiedzialnością z siedzibą w Warszawie, adres: ul. Wilcza nr 19 lok. 37, 00-544 Warszawa, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m.st. Warszawy, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000947005, posiadającą kapitał zakładowy w wysokości 1.055.000,00 zł, posiadającą REGON: 521025726 oraz NIP: 5252892408 (dalej „"), _c('strong', [_vm._v("Pożyczkobiorca")]), _vm._v("”), reprezentowaną przez Arkadiusza Kownackiego – prezesa zarządu"), _c('em', [_vm._v(",")]), _vm._v(" który oświadcza, że jego uprawnienie do reprezentacji nie zostało odwołane, zmienione ani nie wygasło")])]), _c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v("4.")]), _c('p', {
    staticClass: "has-text-danger",
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Michałem Jasińskim, synem Tomasza i Małgorzaty, PESEL 80070708754, radcą prawnym prowadzący Kancelarię Radcy Prawnego w Warszawie przy ul. Chmielnej 2/31, 00-020 Warszawa, posiadającym NIP 534-216-03-53 oraz REGON 260124670 (dalej „"), _c('strong', [_vm._v("Administrator Hipoteki")]), _vm._v("”) ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Administrator Hipoteki")]), _vm._v("” oznacza „Administratora Hipoteki” opisanego powyżej w Sekcji „Strony Umowy”")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Hipoteka")]), _vm._v("” oznacza hipotekę umowną łączną, z najwyższym pierwszeństwem zaspokojenia (hipoteka na pierwszym miejscu), obciążającą prawo użytkowania wieczystego Nieruchomości, ustanowioną przez Pożyczkobiorcę na rzecz Administratora Hipoteki, do sumy 9 150 000,00 PLN (słownie: dziewięć milionów sto pięćdziesiąt tysięcy złotych) w celu zabezpieczenia wierzytelności pieniężnych konkretnych pożyczkodawców udzielających Pożyczkobiorcy pożyczek pieniężnych zabezpieczonych hipotecznie na sfinansowanie Przedsięwzięcia, w szczególności na zabezpieczenie wierzytelności wynikających z Umowy Pożyczki, która to hipoteka zostanie ujawniona w księgach wieczystych prowadzonych dla Nieruchomości wraz z danymi wszystkich powyżej wspomnianych pożyczkodawców (wskazując ich imiona i nazwiska) oraz danymi umów pożyczek przez nich zawartych (wskazując numery i daty tych umów)")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Nieruchomości")]), _vm._v("” oznaczają łącznie:")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("nieruchomość stanowiącą działkę ewidencyjną nr "), _c('strong', [_vm._v("346/19")]), _vm._v(" o powierzchni około 0.1639 ha, powstałą w wyniku podziału działki nr 346/9 położonej w Łodzi przy ulicy Wierzbowej 48A, dla której Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XVI Wydział Ksiąg Wieczystych prowadzi księgę wieczystą nr "), _c('strong', [_vm._v("LD1M/00121640/6")]), _vm._v(",")])]), _c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("udział w prawie użytkowania wieczystego nieruchomości stanowiącej działkę ewidencyjna nr "), _c('strong', [_vm._v("346/18")]), _vm._v("  o powierzchni 0,1092 ha, położonej w Łodzi przy ulicy Wierzbowej, dla której Sąd Rejonowy Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XVI Wydział Ksiąg Wieczystych prowadzi księgę wieczystą nr "), _c('strong', [_vm._v("LD1M/00097963/8")]), _vm._v(", wraz z udziałem w prawie własności urządzenia znajdującego się na przedmiotowej nieruchomości")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Pożyczkobiorca")]), _vm._v("” oznacza „Pożyczkobiorcę” opisanego powyżej w Sekcji „Strony Umowy”")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Pożyczkodawca")]), _vm._v("” oznacza „Pożyczkodawcę” opisanego powyżej w Sekcji „Strony Umowy”")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-title",
    staticStyle: {
      "white-space": "normal"
    },
    attrs: {
      "data-node-view-wrapper": ""
    }
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "data-node-view-content": "",
      "data-type": "custom-title-node"
    }
  }, [_c('u', [_vm._v("TYTUŁ NA DOLE STRONY")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Pożyczkodawcy")]), _vm._v("” oznacza wszystkie osoby, w tym Pożyczkodawcę, które udzieliły lub udzielą Pożyczkobiorcy pożyczek pieniężnych zabezpieczonych hipotecznie z przeznaczeniem na sfinansowanie Przedsięwzięcia")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Przedsięwzięcie")]), _vm._v("” oznacza przedsięwzięcie polegające na:")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("nabyciu prawa użytkowania wieczystego Nieruchomości (w tym zapłacie ceny nabycia prawa użytkowania wieczystego Nieruchomości oraz pokryciu kosztów związanych z tym nabyciem i pozyskaniem finansowania);")])]), _c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("nabyciu autorskich praw majątkowych i praw zależnych do projektów dotyczących Nieruchomości, posiadanych przez sprzedającego prawo użytkowania wieczystego Nieruchomości ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Strony")]), _vm._v("” oznaczają strony niniejszej Umowy")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Umowa")]), _vm._v("” oznacza niniejszą umowę o powołaniu administratora hipoteki")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Umowa Pożyczki")]), _vm._v("” oznacza umowę pożyczki nr "), _c('strong', [_vm._v("2025/02/005 ")]), _vm._v("z dnia 26-02-2025 roku zawartą pomiędzy Pożyczkodawcą a Pożyczkobiorcą, na podstawie której Pożyczkodawca pożyczył Pożyczkobiorcy kwotę "), _c('strong', [_vm._v("10 000,00 PLN ")]), _vm._v("(słownie: dziesięć tysięcy złotych)"), _c('strong'), _vm._v("z przeznaczeniem na sfinansowanie Przedsięwzięcia")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Umowy Pożyczek")]), _vm._v("” oznacza wszystkie umowy pożyczek, w tym Umowę Pożyczki, które zostały lub zostaną zawarte pomiędzy Pożyczkobiorcą a Pożyczkodawcami, w celu udzielenia Pożyczkobiorcy pożyczek pieniężnych zabezpieczonych Hipoteką w łącznej wysokości nie przekraczającej kwoty "), _c('strong', [_vm._v("7 500 000,00")]), _vm._v(" "), _c('strong', [_vm._v("PLN (słownie: siedem milionów pięćset tysięcy złotych)")]), _vm._v(","), _c('strong'), _vm._v("z przeznaczeniem na sfinansowanie Przedsięwzięcia")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Ustawa o Księgach Wieczystych i Hipotece")]), _vm._v("” oznacza ustawę o księgach wieczystych i hipotece z dnia 6 lipca 1982 roku, z późniejszymi zmianami")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("„"), _c('strong', [_vm._v("Uzasadnione Koszty")]), _vm._v("” ma znaczenie nadane temu terminowi w § 7 niniejszej Umowy")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("tytuły sekcji zostały umieszczone wyłącznie dla ułatwienia odniesień i nie mają wpływu na wykładnię i interpretację Umowy;")])]), _c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("odniesienie do umowy lub innego dokumentu jest odniesieniem do tego dokumentu ze wszelkimi zmianami wprowadzanymi zgodnie z jego warunkami;")])]), _c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("o ile kontekst nie wskazuje inaczej, odniesienia do liczby pojedynczej obejmują liczbę mnogą i "), _c('em', [_vm._v("vice versa")]), _vm._v(".")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("1.  Pożyczkodawca powołuje niniejszym radcę prawnego Michała Jasińskiego jako Administratora Hipoteki w rozumieniu art. 68"), _c('sup', [_vm._v("2")]), _vm._v(" Ustawy o Księgach Wieczystych i Hipotece, w celu zabezpieczenia Hipoteką wszystkich wierzytelności pieniężnych Pożyczkodawców z tytułu Umów Pożyczek (w tym wierzytelności o spłatę kwot głównych pożyczek, zapłatę odsetek kapitałowych oraz odsetek za opóźnienie).")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('u', [_vm._v("POJEDYNCZY WIERSZ NA POCZĄTKU STRONY")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("zawiadomi Pożyczkodawcę o dokonaniu wpisu lub zmiany Hipoteki do ksiąg wieczystych prowadzonych dla Nieruchomości, a jeśli zajdzie taka potrzeba jest uprawniony podjąć wszelkie działania w celu sprostowania lub zmiany jakichkolwiek nieprawidłowości lub błędów związanych z dokonaniem takiego wpisu do ksiąg wieczystych; oraz")])]), _c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("po uprawomocnieniu się wpisu dotyczącego Hipoteki w księgach wieczystych prowadzonych dla Nieruchomości zawiadomi o tym Pożyczkodawcę.")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('u', [_vm._v("TABULATORY")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("4.     POUFNOŚĆ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("4.1.         ")]), _vm._v("Każda ze Stron zobowiązuje się zachować w poufności Informacje Poufne i nie ujawniać ich Osobom Trzecim, nie wykorzystywać tajemnicy przedsiębiorstwa drugiej ze Stron we własnym zakresie w celu osiągnięcia korzyści majątkowych poza Inwestycją, oraz zapewnić, aby Informacje Poufne były chronione z dołożeniem takiego samego stopnia staranności, jaki stosuje się do ochrony własnych informacji o charakterze Informacji Poufnych.")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("4.2.         ")]), _vm._v("Każda ze Stron zobowiązuje się nie ujawniać Informacji Poufnych Osobom Trzecim, z wyjątkiem sytuacji, w których:")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("4.2.1.    ")]), _vm._v("będzie to wymagane przepisami prawa lub zażąda tego właściwy organ w ramach ustawowo przyznanych mu uprawnień do żądania takich informacji, lub")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("4.2.2.    ")]), _vm._v("są znane Stronie z innych źródeł, bez obowiązku zachowania ich w tajemnicy oraz bez naruszenia postanowień Umowy, lub")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("4.2.3.    ")]), _vm._v("Strona uzyska na to uprzednią zgodę, wyrażoną w formie pisemnej pod rygorem nieważności, wszystkich pozostałych Stron, lub   ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-type": "custom-page-divider"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("5. Pożyczkobiorca oraz Pożyczkodawca wyrażają zgodę na otrzymywanie od Administratora Hipoteki faktur drogą elektroniczną na adresy wskazane zgodnie z postanowieniami poniższego § 8 ("), _c('em', [_vm._v("Doręczenia")]), _vm._v(").")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("wykonania przez Administratora Hipoteki niniejszej Umowy lub czynności Administratora Hipoteki, w szczególności wszelkich kosztów wpisów i opłat sądowych, kosztów komorniczych i innych związanych z dochodzeniem na rzecz Pożyczkodawców zaspokojenia z przedmiotu Hipoteki (dalej „"), _c('strong', [_vm._v("Uzasadnione Koszty")]), _vm._v("”). Administrator Hipoteki może żądać od Pożyczkobiorcy oraz Pożyczkodawcy zaliczki na poczet Uzasadnionych Kosztów lub jakiejkolwiek ich części. Przed otrzymaniem powyżej wspomnianej zaliczki, w wysokości niezbędnej do pokrycia Uzasadnionych Kosztów, Administrator Hipoteki nie będzie zobowiązany do ponoszenia samodzielnie jakichkolwiek Uzasadnionych Kosztów. Administrator Hipoteki złoży Pożyczkodawcy zestawienie poniesionych Uzasadnionych Kosztów.")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('em', [_c('s', [_c('u', [_vm._v("PRZEKREŚLENIE, PODKREŚLENIE, POCHYLENIE")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('em', [_c('s', [_c('u', [_vm._v("PRZEKREŚLENIE I PODKREŚLENIE, POCHYLENIE")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tableWrapper"
  }, [_c('table', {
    staticStyle: {
      "min-width": "394px",
      "width": "100%"
    }
  }, [_c('colgroup', [_c('col', {
    staticStyle: {
      "width": "25%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "75%"
    }
  })]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Inwestor")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Pierwsze imię: Jan")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Drugie imię:")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Nazwisko: Ryba")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Adres zamieszkania Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("ulica nr budynku , nr lokalu , miejscowość , kod pocztowy , gmina , powiat , województwo")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Adres do korespondencji Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("ulica nr budynku , nr lokalu , miejscowość , kod pocztowy , gmina , powiat , województwo")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Numer PESEL Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Numer i seria dowodu osobistego Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Numer telefonu Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("a")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Rachunek bankowy Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Adres poczty elektronicznej Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("test11@test.com")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Numer Identyfikacji Podatkowej (NIP)")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "25%"
    }
  }, [_c('p', [_c('strong', [_vm._v("Urząd Skarbowy właściwy dla Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "width": "75%"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })])])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tableWrapper"
  }, [_c('table', {
    staticStyle: {
      "min-width": "381px",
      "border-collapse": "collapse",
      "border": "1pt solid #A9A9A9",
      "border-spacing": "0px",
      "width": "100%"
    }
  }, [_c('colgroup', [_c('col', {
    staticStyle: {
      "width": "356px"
    }
  }), _c('col')]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "2",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Inwestor")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Pierwsze imię: [_]")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Drugie imię: [_]")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Nazwisko: [_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Adres zamieszkania Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("ulica [_] nr budynku [_], nr lokalu [_], miejscowość [_], kod pocztowy [_], gmina [_], powiat [_], województwo [_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Adres do korespondencji Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("ulica [_] nr budynku [_], nr lokalu [_], miejscowość [_], kod pocztowy [_], gmina [_], powiat [_], województwo [_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Numer PESEL Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("[_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Numer i seria dowodu osobistego Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("[_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Numer telefonu Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("[_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Rachunek bankowy Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("[_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Adres poczty elektronicznej Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("[_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Numer Identyfikacji Podatkowej (NIP) [wypełnić w przypadku osoby fizycznej prowadzącej działalność gospodarczą]")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("[_]")])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1",
      "colwidth": "356"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Urząd Skarbowy właściwy dla Inwestora")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("[_]")])])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-title",
    staticStyle: {
      "white-space": "normal"
    },
    attrs: {
      "data-node-view-wrapper": ""
    }
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "data-node-view-content": "",
      "data-type": "custom-title-node"
    }
  }, [_vm._v("TABELA - BRAK możliwości kopiowania zawartosci komórki z tabeli jeśli występuje enter")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tableWrapper"
  }, [_c('table', {
    staticStyle: {
      "min-width": "50px",
      "border-collapse": "collapse",
      "border": "1pt solid #A9A9A9",
      "border-spacing": "0px",
      "width": "100%"
    }
  }, [_c('colgroup', [_c('col'), _c('col')]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "2",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_vm._v("PODSUMOWANIE")])])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('strong', [_vm._v("Inwestor")])])]), _c('td', {
    attrs: {
      "colspan": "1",
      "rowspan": "1"
    }
  }, [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Pierwsze imię: [_]")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Drugie imię: [_]")]), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("Nazwisko: [_]")])])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-type": "splitTextSection"
    }
  }, [_c('div', {
    attrs: {
      "data-type": "splitTextColumn"
    }
  }, [_c('p', [_vm._v("Za Administratora Hipoteki  ")]), _c('p', [_vm._v(" ")]), _c('p', [_vm._v("__________________________")]), _c('p', [_vm._v("Michał Jasiński")])]), _c('div', {
    attrs: {
      "data-type": "splitTextColumn"
    }
  }, [_c('p', [_vm._v("Za Pożyczkobiorcę ")]), _c('p', [_vm._v(" ")]), _c('p', [_vm._v("__________________________")]), _c('p', [_vm._v("Arkadiusz Kownacki")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-title",
    staticStyle: {
      "white-space": "normal"
    },
    attrs: {
      "data-node-view-wrapper": ""
    }
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "data-node-view-content": ""
    }
  }, [_vm._v(" UCINANIE TESTU, GDY NIE MA SPACJI ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ol', [_c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" fdfdf")]), _c('ol', [_c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbcccccc ")])])])]), _c('li', [_c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v("ssdsd")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c('br', {
    staticClass: "ProseMirror-trailingBreak"
  })]);
}]

export { render, staticRenderFns }