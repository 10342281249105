


































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniDropdownItem from "@/components/sygni-dropdown/SygniDropdownItem.vue";
import SygniDropdown from "@/components/sygni-dropdown/SygniDropdown.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue"
import UserPresentation from "@/components/UserPresentation.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniCart from "@/components/layout/SygniCard.vue";
import SygniLinkButton from "@/components/buttons/SygniLinkButton.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SimplePostBox from '@/modules/genprox/modules/insights/components/SimplePostBox.vue';
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {mapState} from "vuex";
import {BSpinner} from "bootstrap-vue";
import ProductCard from "@/modules/genprox/modules/fund/modules/fundraising/components/product/ProductCard.vue";
import FreeTools from "@/modules/genprox/components/welcome/FreeTools.vue";
import Packages from "@/modules/genprox/components/welcome/Packages.vue";
import AuthorizeData from "@/modules/genprox/components/welcome/AuthorizeData.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
const mailStyles = require('!!raw-loader!./mail-styles.css')

@Component({
  components: {
    AuthorizeData,
    Packages,
    FreeTools,
    ProductCard,
    SygniContainerTitle,
    SygniRoundedButton, BSpinner,
    SygniCart, SygniLinkButton, SygniArrowButton, UserPresentation, SygniDropdownItem, SygniDropdown, SygniSquareButton, SimplePostBox, SygniLoader },
    computed:{
      ...mapState('genprox', {
        activeUserContext: (state: any) => state.activeUserData.context,
        welcomeText: (state: any) => state.welcomeText,
        authorized: (state: any) => state.activeUserData?.verified,
      }),
      ...mapState('investors', {
        investorData: (state: any) => state.newInvestmentClient,
      }),
      ...mapState('auth', {
        contextId: (state: any) => state.jwtTokenData?.context_id,
      }),
    }
})
export default class Pdf extends Vue {
  async generatePdf() {
    const pdfContentPayload = (this.$refs?.pdfTemplate as HTMLDivElement)?.innerHTML

    const payload: any = {
      content: pdfContentPayload,
      stylesheetLink: null,
      stylesheetContent: mailStyles,
      watermark: null,
      // watermark: {
      //   path: 'https://artsign.com/wp-content/uploads/2021/09/AS-Cash__1-1240x1754.jpg',
      //   size: [180, 267],
      //   position: 'P',
      //   alpha: 0.25,
      //   behindContent: true,
      // }
    }

    try {
      const pdfResp = await this.$store.dispatch('genprox/generatePdfFromHtml', payload)

      const url = window.URL.createObjectURL(new Blob([pdfResp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }
}
